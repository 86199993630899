import { graphql, Link } from "gatsby";
import React from "react"

import Layout from "../components/layout"
import { PostTitle } from "../components/post-details/post-details.style";
import SEO from "../components/seo"
import { PageContentWrapper, PageLink } from '../templates/templates.style';

export const pageQuery = graphql`
  query NotFoundPageQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const NotFoundPage = (props: any) => {
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  
  return (
    <Layout isHideInstagram={true}>
      <SEO title="404: Not found" />
      <PageContentWrapper>
        <PostTitle>Woops! NOT FOUND</PostTitle>
        <p>You just hit a page that doesn&#39;t exist... the sadness. Please visit: <PageLink to="/">{siteUrl}</PageLink></p>
      </PageContentWrapper>
    </Layout>
  )
}

export default NotFoundPage
